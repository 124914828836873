import React from "react";
import AboveFold from "../components/aboveFold";
import ContactForm from "../components/contactForm";
import Hr from "../components/hr";
import Name from "../components/name";
import Layout from "../components/layout";
import SectionHeading from "../components/sectionHeading";
import { 
  Container, 
  Row, 
  Col, 
} from "react-bootstrap";
import { 
  useStaticQuery, 
  graphql, 
  Link,
} from "gatsby";


const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          tagline
          ctaText
          ctaLink
          lmText
          lmLink
        }
      },
      background: file(relativePath: { eq: "fealy_8x10-on-path-b.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // jesse_10x16.png"
  
  return (
  <Layout>
  
    <AboveFold 
      canonical="404"
      title="Page not found"
      description={data.site.siteMetadata.tagline}
      ctaText={data.site.siteMetadata.ctaText}
      ctaLink={data.site.siteMetadata.ctaLink}
      lmText={data.site.siteMetadata.lmText}
      lmLink={data.site.siteMetadata.lmLink}
      img={data.background.childImageSharp.fluid}
      >
        <h1><Name/></h1>
        {/* <div className="cg-tagline">{data.site.siteMetadata.tagline}</div> */}
        {/* <div className="cg-problem">Kick&nbsp;mini-storage&nbsp;fees. Boost&nbsp;property&nbsp;value.</div> */}
        <div className="cg-problem">{data.site.siteMetadata.tagline}</div>
      </AboveFold>
      
    <Hr/>
    
    <main id='main'>
      <div className='cg-sectionLight'>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading>Page Not Found</SectionHeading>
            </Col>
          </Row>
        <Col>
          <p>The page you requested could not be found. Here are some other options:</p>
          <ul>
            <li><Link to="/schedule" className="cg-linkDark">Book Your Shed</Link></li>
            <li><a href="https://info.uticashedpros.com/f/checklist" className="cg-linkDark">Shed Buyer's Checklist</a></li>
            <li><Link to="/review" className="cg-linkDark">Leave a Review</Link></li>
            <li><Link to="/features" className="cg-linkDark">Features</Link></li>
            <li><Link to="/pricing" className="cg-linkDark">Pricing</Link></li>
          </ul>
        </Col>
        </Container>
      </div>
      <Hr/>
    </main>
    <ContactForm/>
  </Layout>
)}

export default NotFoundPage
